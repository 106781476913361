<script setup lang="ts">
import { VMenu } from "vuetify/components/VMenu";

const props = defineProps<{
  closeOnContentClick?: boolean;
  offset?: string | number | number[];
  maxWidth?: string | number;
  location?: Anchor;
  disabled?: boolean;
  openOnHover?: boolean;
  openDelay?: number;
  width?: string | number;
  attach?: boolean;
  eager?: boolean;
}>();

const modelValue = defineModel<boolean>({
  required: false,
});
</script>

<template>
  <VMenu
    v-bind="props"
    v-model="modelValue"
    :minWidth="props.width"
    :location="props.location ?? 'bottom'"
    :attach="props.attach ?? false"
    :eager="props.eager"
  >
    <template
      v-if="$slots['activator']"
      #activator="{ props: slotProps }"
    >
      <slot
        name="activator"
        v-bind="{ props: slotProps }"
      />
    </template>
    <template
      v-if="$slots['default']"
      #default
    >
      <slot name="default" />
    </template>
  </VMenu>
</template>
